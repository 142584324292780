<!-- 修改密码页面 -->
<template>
  <div>
    <a-card title="密码修改">
      <a-form ref="formRef" :model="formState" :rules="rules" v-bind="layout">
        <a-row>
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <a-form-item
              has-feedback
              label="旧密码"
              name="oldPass"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-input-password
                v-model:value="formState.oldPass"
                placeholder="请输入原始密码"
              >
                <template v-slot:prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input-password>
            </a-form-item>
          </a-col>
          <a-col :span="7"></a-col>
        </a-row>
        <a-row>
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <a-form-item
              has-feedback
              label="新密码"
              name="newPass"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-input-password
                v-model:value="formState.newPass"
                placeholder="请输入新密码"
              >
                <template v-slot:prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input-password>
            </a-form-item>
          </a-col>
          <a-col :span="7"></a-col>
        </a-row>
        <a-row>
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <a-form-item
              has-feedback
              label="确认密码"
              name="checkPass"
              :labelCol="formLayout2X2.labelCol"
              :wrapperCol="formLayout2X2.wrapperCol"
            >
              <a-input-password
                v-model:value="formState.checkPass"
                placeholder="请再次输入新密码"
                autocomplete="off"
              >
                <template v-slot:prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input-password>
            </a-form-item>
          </a-col>
          <a-col :span="7"></a-col>
        </a-row>
        <a-row>
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <a-row>
              <a-col :span="8"></a-col>
              <a-col :span="8"></a-col>
              <a-col :span="8">
                <a-button style="margin-right: 10px" @click="resetForm">
                  <RollbackOutlined />重置
                </a-button>
                <a-button type="primary" @click="onSubmit">
                  <EnterOutlined />提交
                </a-button>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="7"></a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
</template>
<script>
import { reactive, toRefs, defineComponent, ref } from "vue";
import {
  LockOutlined,
  EnterOutlined,
  RollbackOutlined,
} from "@ant-design/icons-vue";
import { checheUserPassword, updateUserPassword } from "@/api/user";
import { message } from "ant-design-vue";
import router from "../../../router";
export default defineComponent({
  name: "PwdSettings",
  components: {
    LockOutlined,
    EnterOutlined,
    RollbackOutlined,
  },
  setup() {
    const formRef = ref();
    const state = reactive({
      formState: {
        oldPass: "",
        newPass: "",
        checkPass: "",
      },
      formLayout2X2: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 18,
        },
      },
    });
    const resetForm = () => {
      formRef.value.resetFields();
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then((success) => {
          if (success) {
            updateUserPassword({ id: null, pwd: state.formState.newPass }).then(
              (resp) => {
                if (resp.code == 200) {
                  message.info("系统将在5秒钟后进入登录页面。。。。。。");
                  setTimeout(() => {
                    router.replace("/");
                  }, 5000);
                } else {
                  message.error("密码更新失败！！！");
                }
              }
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const validateOldPass = async (rule, value) => {
      // if (value === "") {
      //   return Promise.reject("原始密码不能为空！");
      // }
      if (value.length < 6 || value.length > 12) {
        return Promise.reject("密码长度必须为6-12位！");
      }
      if (value && value != "") {
        await checheUserPassword(value).then((resp) => {
          if (resp.code == 101) {
            return Promise.reject("原始密码不正确！");
          }
        });
      }
      return Promise.resolve();
    };
    const validateNewPass = async (rule, value) => {
      // if (value === "") {
      //   return Promise.reject("新密码不能为空！");
      // }
      if (value.length < 6 || value.length > 12) {
        return Promise.reject("密码长度必须为6-12位！");
      }
      if (value === state.formState.oldPass) {
        return Promise.reject("请输入一个新密码!");
      }
      return Promise.resolve();
    };
    const validateCheckPass = async (rule, value) => {
      // if (value === "") {
      //   return Promise.reject("确认密码不能为空！");
      // }
      if (value.length < 6 || value.length > 12) {
        return Promise.reject("密码长度必须为6-12位！");
      }
      if (value !== state.formState.newPass) {
        return Promise.reject("两次密码输入不一致!");
      }
      return Promise.resolve();
    };
    const rules = {
      oldPass: [
        { required: true, message: "原始密码不能为空" },
        { validator: validateOldPass, trigger: "blur" },
      ],
      newPass: [
        { required: true, message: "新密码不能为空" },
        { validator: validateNewPass, trigger: "blur" },
      ],
      checkPass: [
        { required: true, message: "确认密码不能为空" },
        { validator: validateCheckPass, trigger: "blur" },
      ],
    };
    const refData = toRefs(state);
    return {
      ...refData,
      formRef,
      rules,
      resetForm,
      onSubmit,
    };
  },
});
</script>
<style lang='scss' scoped>
</style>